import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivationService {

  constructor(private http: HttpClient, private router: Router) { }

  addActivationCode(data: any): Observable<any> {
    return this.http.post<any>(environment.apiHost + 'api/v1/activation-code', data);
  }

  getActivationCodeList(payload: any): Observable<any> {
    const endPoint = `api/v1/activation-code?page=${payload.pageNumber}&limit=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}&search=${payload.search}`
    return this.http.get<any>(environment.apiHost + endPoint).pipe(
      catchError(this.handleError)
    );
  }

  deleteActivationCode(id: any): Observable<any> {
    return this.http.delete<any>(environment.apiHost + `api/v1/activation-code/${id}`);
  }

  updateActivationCode(id: any, data: any): Observable<any> {
    return this.http.put<any>(environment.apiHost + `api/v1/activation-code/${id}`, data);
  }

  private handleError(error: HttpErrorResponse) {
    console.log("neweroor",error.error.message)

    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
    } else if(error.error.message) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorMessage = error.error.message;
    }else {
      errorMessage =  error.error.error;
    }
    return throwError(errorMessage);
  }

  activationCodeSampleFile() {
    return this.http.get(
      '/assets/sample-files/activation-codes.xlsx', { responseType: 'blob' }
    );
  }

}
