
        <div class="modal-header">
           
          <button *ngIf="paymentStatus === false" type="button" class="btn-close" (click)="close()"><img src="../../../assets/img/modal-close.svg" alt=""></button>
        </div>
        <div class="modal-body">
            <div class="success-ico">
                <img *ngIf="paymentStatus === true" src="../../../assets/img/icon-success.png" alt="success">
                <img *ngIf="paymentStatus === false" src="../../../assets/img/icons8-card-error-64.png" alt="success">
            </div>
            <h2  class="modal-title" *ngIf="paymentStatus === true && subStatus?.success">{{'Successfully Subscribed'}} </h2>
            <h2  class="modal-title" *ngIf="paymentStatus === false && (!subStatus?.success || (subStatus?.success))">{{'Failed'}} </h2>
           <p  class="modal-desc"> {{paymentStatus === true ? '' : errormsg}}</p>
           <p  class="modal-desc" *ngIf="paymentStatus === true && errormsg === 'Already Subscribed'"> {{errormsg}}</p>
           <div *ngIf="paymentStatus === true && plan?.id" class="plan-head">
            
                <div class="plan-titles text-center" *ngIf="plan?.id">
                    <div class="txt">
                        <h3 class="service">{{plan?.name}}</h3>
                        <h3 class="rate">
                            <span class="amt">${{plan?.amount}}</span>
                            <span class="billcycle">/{{plan?.period}}</span>
                        </h3>
                        <!-- <h3 class="service">Premium Category</h3> -->
                    </div>
                    <div class="ico">
                        <img src="../../../assets/img/plan-icon.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div  class="modal-footer" *ngIf="subStatus?.success && (errormsg==null || errormsg==='Already Subscribed') ">
            <div class="footer-table">
                <table class="table" *ngIf="subStatus?.success && errormsg==null &&plan?.id">
                    <tr>
                        <td>Transaction Date</td>
                        <td class="text-end"><span class="val">
                             {{ (subStatus?.data?.transactionDate || subStatus?.transactionDate) | date: 'MM-dd-yyyy hh:mm a' }}
                        </span></td>
                    </tr>
                    <tr>
                        <td>Transaction ID <span></span></td>
                        <td class="text-end"><span class="val">{{subStatus?.data?subStatus?.data.transactionId:subStatus?.transactionId}}</span></td>
                    </tr>
                </table>
            </div>

            <div class="plan-titles text-center" *ngIf="subStatus?.success && (errormsg==null || errormsg==='Already Subscribed')">
                <span>Don't go back or  refresh. it will automatically redirect to home page</span>
            </div>
        </div>
    
   
      <!-- <ngx-spinner bdColor = "white" size = "medium" color = "rgb(97, 190, 21)" type = "ball-scale-multiple" [fullScreen] = "false"></ngx-spinner> -->
    