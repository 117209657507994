import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentStripeServiceService {
  
  
  StripePublicKey = environment.STRIPE_KEY; 

  constructor(private http: HttpClient) {}

  createSetupIntent(planId:any,payload:any): Observable<any>{
    const endPoint = `api/v1/subscription/setup-intent/`;
    return this.http.post<any>(environment.apiHost +endPoint+planId,payload).pipe(
      catchError(this.handleError)
    );
  }

  subscribe(payload:any): Observable<any>{
    const endPoint = `api/v1/subscription/`;
    return this.http.post<any>(environment.apiHost +endPoint,payload).pipe(
      catchError(this.handleError)
    );
  }


  checkActive(subId:any): Observable<any>{
    const endPoint = `api/v1/subscription/reactivate/`;
    return this.http.post<any>(environment.apiHost +endPoint,subId).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.log("neweroor2",error)
    console.log("neweroor",error.error.message)

    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
    } else if(error.error.message) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorMessage = error.error.message;
    }else {
      errorMessage =  error.error.error;
    }

    return throwError(errorMessage);
  }


}
