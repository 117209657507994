import { ChangeDetectorRef, Component, EventEmitter, Input,  OnInit, SimpleChanges, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { injectStripe } from 'ngx-stripe';
import { PaymentStripeServiceService } from '../../modules/service/payment/payment-stripe-service.service';
import { PaymentComponent } from '../../modules/authentication/payment/payment.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-paymentpopup',
  templateUrl: './paymentpopup.component.html',
  styleUrl: './paymentpopup.component.scss'
})
export class PaymentpopupComponent implements OnInit{
  @Input() paymentStatus :any;
  @Input() errormsg:any=null;
  plan:any;
  @Input() confirmSubscription:any;
  @Input() subStatus: any;
  
  private readonly stripeService = inject(PaymentStripeServiceService);
  readonly stripe = injectStripe(this.stripeService.StripePublicKey);
  constructor(private router:Router,private route:ActivatedRoute,private activeModal: NgbActiveModal,private spinner: NgxSpinnerService){}
 
  ngOnInit(){
    let plans:any = sessionStorage.getItem('plan');
    this.plan =JSON.parse(plans)
    this.spinner.hide();
 console.log(this.subStatus)
  }

  close(){
    if(this.confirmSubscription){
     this.router.navigate(['/home'])
    }
    this.activeModal.close();
  }
}
