<div class="top-nav">
  <div class="top-bar"  *ngIf="!isVerified">
    <span class="ico">
      <img src="../../../assets/img/top-email.svg" alt="top-email"
    /></span>
    <span class="txt"> Please Verify your email</span>
    <button class="btn btn-buy" (click)="onVerifyNow()">
      Verify Now
      <span class="spinner-border spinner-border-sm ms-1" *ngIf="verifyLoading" role="status"></span>
    </button>
  </div>
  <div class="inner-wrp">
      <div class="left-sec">
          <a class="navbar-brand">
            <div class="" *ngIf="loading ">
                  <ngx-skeleton-loader class="skeleton-circle" count="1" appearance="circle"
                      ></ngx-skeleton-loader>
          </div>
            <span *ngIf="!loading">
                <img [src]="userData1?.logo ? userData1?.logo : '../../../assets/img/logo.png'" alt="" (load)="loading = false" 
                (error)="loading = false">
            </span>
          </a>
          <a class="navbar-brand small">
            <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
            <span *ngIf="!loading">
              <img [src]="userData1?.logo ? userData1?.logo : '.../../../assets/img/logo.png'" alt="">
            </span>
          </a>
          <span class="navigation-icon toggle" (click)="sidebar()">
            <img
                [src]="sideBar ? '../../../assets/img/btn-arrow-prev.svg' : '../../../assets/img/arrow-left-side-nav.svg'"
                alt="Toggle Sidebar">
        </span>
        <!-- <span class="navigation-icon toggle" (click)="sidebar()">
            <img
                [src]="sideBar ? '../../../assets/img/arrow-left-side-nav.svg' : '../../../assets/img/btn-arrow-prev.svg'"
                alt="Toggle Sidebar">
        </span> -->

      </div>
      <div class="main-title">
          {{setHeaderTitle()}}
      </div>
      <div class="profile-info-rt">
          <!-- <div class="prof-img-wrp">
              <img src="../../../assets/img/profile-user-img.png" alt="User Image" class="prof-img">
          </div> -->
          <div class="prf-dtl">
              <span class="prof-name">{{userData1?.name}}</span>
              <!-- <h5 class="prof-desg">Seo Marketing</h5> -->
          </div>
      </div>
  </div>
</div>
