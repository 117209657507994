import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../modules/service/auth-service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent {

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
  ) {}

  close(): void {
    this.activeModal.close();
  }

  onLogout() {
    this.activeModal.close();
    this.authService.logout();
  }

}
