import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropzoneService {
  private submittingSubject = new BehaviorSubject<boolean>(false);
  submitting$ = this.submittingSubject.asObservable();

  setSubmitting(submitting: boolean) {
    console.log(submitting)
    this.submittingSubject.next(submitting);
  }
}