import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../modules/service/profile-service';
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, PhoneNumberFormat, CountryISO } from 'ngx-intl-tel-input';
import { CommonValidator } from '../validators/common.validator';
import { AuthService } from '../../modules/service/auth-service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  profileForm!: FormGroup;
  @Input() userDetails: any;
  @Output() profileEdited = new EventEmitter<string>();
  loading: boolean = false;
  isedit: boolean = true;
  dropImage: any | null = null;
  isAddimgRemove: boolean = false;
  imgUpProgress:boolean = false;
  imageData: any = {
    name: '',
    url: '',
    component: 'client'
  };
  isLoading : boolean = false;


  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  selectedDialCodeDtls: any = {
    name: 'US',
    code: ''
  }
  country: any = [];

  primaryColors = ['#C8B88A', '#E39D8C', '#E0B156', '#75CE9F', '#9EC278', '#B6BAD5', '#DF9A56', '#DD8E8B', '#83C7CF', '#D1B9BC', '#C4C3C3'];
  secondaryColors = ['#FFD1A4', '#C7DAC0', '#A899C0', '#FFA4D8', '#CACACA', '#7991D1'];



  dialCodeList: any;
  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private authService: AuthService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    // Initialize form controls
    this.getCountryList();

    if (this.userDetails?.billingAddress) {
      this.profileForm = this.fb.group({
        firstName: ['', [Validators.required, CommonValidator.firstName]],
        lastName: ['', [Validators.required, CommonValidator.lastName]],
        email: [{ value: '', disabled: true }],
        phoneNumber: [''], // Adjusted pattern for 10-digit phone number
        primaryColor: [''],
        secondaryColor: [''],
        logo: [null], // Initialize without validators
        apartment: ['', [Validators.required, this.trimValidator.bind(this), Validators.pattern(/^[\w /-]*$/)]],
        address: ['', [Validators.required, this.trimValidator.bind(this)]],
        country: [null, Validators.required],
        city: ['', [Validators.required, this.trimValidator.bind(this), this.lettersOnlyValidator]],
        state: ['', [Validators.required, this.trimValidator.bind(this), this.lettersOnlyValidator]],
        pinCode: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(8),
            Validators.pattern('^[0-9]*$'), // Ensures only numeric values
          ]
        ],
      });
    } else {
      this.profileForm = this.fb.group({
        firstName: ['', [Validators.required, CommonValidator.firstName]],
        lastName: ['', [Validators.required, CommonValidator.lastName]],
        email: [{ value: '', disabled: true }],
        phoneNumber: [''], // Adjusted pattern for 10-digit phone number
        primaryColor: [''],
        secondaryColor: ['']
      });
    }

    // Conditionally set validators for 'logo' field based on user role
    if (this.userDetails.role_id !== 1) { // If not an admin
      // this.profileForm.get('logo')?.setValidators(Validators.required);
      this.profileForm.get('primaryColor')?.setValidators(Validators.required);
      this.profileForm.get('secondaryColor')?.setValidators(Validators.required);
    } else {
      this.profileForm.get('logo')?.clearValidators();
      this.profileForm.get('primaryColor')?.clearValidators();
      this.profileForm.get('secondaryColor')?.clearValidators();
    }

    // Ensure the form control's validity is updated
    this.profileForm.get('logo')?.updateValueAndValidity();
    this.profileForm.get('primaryColor')?.updateValueAndValidity();
    this.profileForm.get('secondaryColor')?.updateValueAndValidity();


    // Set form data
    this.setData();
  }

  setData(): void {
    this.selectedDialCodeDtls.code = this.userDetails?.country_code
    this.getCountryDialCode();

    if (this.userDetails?.billingAddress) {
      this.profileForm.patchValue({
        firstName: this.userDetails.first_name,
        lastName: this.userDetails.last_name,
        email: this.userDetails.email,
        phoneNumber: this.userDetails.phone,
        primaryColor: this.userDetails.primary_color,
        secondaryColor: this.userDetails.secondary_color,
        apartment: this.userDetails?.billingAddress?.apartment,
        address: this.userDetails?.billingAddress?.address,
        city: this.userDetails?.billingAddress?.city,
        state: this.userDetails?.billingAddress?.state,
        pinCode: this.userDetails?.billingAddress?.postal_code
      });
    } else {
      this.profileForm.patchValue({
        firstName: this.userDetails.first_name,
        lastName: this.userDetails.last_name,
        email: this.userDetails.email,
        phoneNumber: this.userDetails.phone,
        primaryColor: this.userDetails.primary_color,
        secondaryColor: this.userDetails.secondary_color,
      });
    }

    console.log(this.profileForm, "this.profileForm");
    this.isedit = (this.userDetails?.logo) ? true : false;
    this.imageData.name = 'name';
    // this.imageData.url = this.userDetails?.logo;
    console.log("Image data:", this.imageData);

    if (this.userDetails?.logo) {
      this.dropImage = this.userDetails.logo;
      this.profileForm.get('logo')?.setValue(this.dropImage);
    }
  }

  getCountryList() {
    this.isLoading = true;
    this.authService.countryist().subscribe((res: any) => {
      if (res.success && res.statusCode === 200) {
        this.country = res.data;
        this.isLoading = false;
        const selectedCountry = this.country.find(
          (value: any) =>
            value.country ===
            (this.userDetails?.billingAddress?.country || "United States")
        );
        this.profileForm.patchValue({
          country: selectedCountry
        });
      }
      
    }, (err: any) => {
      this.toaster.error(err?.error?.message || err || "Something went wrong", "Error!", {
        progressBar: true,
        closeButton: true,
        timeOut: 3000,
      });
      this.isLoading = false;
    });
  }

  restrictSpecialCharacters(event: any) {
    if (event.target.id === 'country-search-box') {
      // This is the country search box, no replacement needed
      return;
    }
    const input = event.target;
    const cleanValue = input.value.replace(/[^0-9+]/g, ''); // Allows only numbers, spaces, and the plus sign
    input.value = cleanValue;

    const minLength = 4;
    const maxLength = 20;
    const phoneNumberControl = this.profileForm.get('phoneNumber');
    
    // Extract and clean the phone number value
    let phoneNumber = phoneNumberControl?.value?.number || '';
    phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
  
    // Check for required and length validation
    if (phoneNumber.length === 0) {
      phoneNumberControl?.setErrors({ required: true });
    } else if (phoneNumber.length < minLength || phoneNumber.length > maxLength) {
      phoneNumberControl?.setErrors({ invalidLength: true });
    } else {
      phoneNumberControl?.setErrors(null); // Clear any existing errors if valid
    }
    // this.personalInfoForm.get('phoneNumber')?.setValue(cleanValue); // Update form control value
  }

  getCityError(control: AbstractControl | null): string {
    if (control?.hasError('required')) {
      return "City is required.";
    } else if (control?.hasError('trimError')) {
      return "City cannot start with a space or be just spaces."
    } else if (control?.hasError('lettersOnly')) {
      return "Enter valid city."
    } else {
      return "";
    }
  }

  getStateError(control: AbstractControl | null): string {
    if (control?.hasError('required')) {
      return "State is required.";
    } else if (control?.hasError('trimError')) {
      return "State cannot start with a space or be just spaces."
    } else if (control?.hasError('lettersOnly')) {
      return "Enter valid state."
    } else {
      return "";
    }
  }

  getPinCodeError(control: AbstractControl | null): string {
    if (control?.hasError('required')) {
      return "Postal Code is required.";
    } else if (control?.hasError('pattern')) {
      return "Postal Code must be an integer."
    } else if (control?.hasError('minlength')) {
      return "Postal Code minimum of 4 length."
    } else if (control?.hasError('maxlength')) {
      return "Postal Code maximum of 8 length."
    } else {
      return "";
    }
  }

  getAppartMentError(control: AbstractControl | null): string {
    if (control?.hasError('required')) {
      return "Apartment is required.";
    } else if (control?.hasError('trimError')) {
      return "Apartment details cannot start with a space or be just spaces."
    } else if (control?.hasError('pattern')) {
      return "Ente valid apartment details."
    } else {
      return "";
    }
  }

  trimValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.toString();
    // Check if the value is empty or contains only spaces
    if (value && (value.startsWith(' ') || !value.trim().length)) {
      return { trimError: true };
    }
    return null;
  }
  lettersOnlyValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.toString().trim();

    // Check if the value starts with a space or contains non-letter characters
    const startsWithSpace = control.value?.toString().startsWith(' ');
    const hasOnlyLetters = /^[a-zA-Z\s]*$/.test(value);

    if (startsWithSpace || !hasOnlyLetters || value?.length === 0) {
      return { lettersOnly: true };
    }

    return null;
  }
  removefile(): void {
    this.isedit = false;

    this.dropImage = null;
    this.profileForm.get('logo')?.setValue(null); // Clear the logo field
    this.profileForm.get('logo')?.markAsTouched(); // Mark as touched to trigger validation
    this.profileForm.get('logo')?.updateValueAndValidity();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.profileForm.patchValue({
        logo: file
      });
    }
  }
  getCountryDialCode() {
    this.profileService.getDialCode().subscribe((dialCodeResp: any) => {
      this.dialCodeList =
        dialCodeResp?.data?.map((eachDialCode: any) => {
          const formSelectOption: any = {
            name: eachDialCode.name,
            value: eachDialCode.dial_code,
          };
          return formSelectOption;
        }) || [];
      // if (this.selectedDialCodeDtls?.code) {
      const countryFullName: string =
        this.dialCodeList.find(
          (eachDialCode: any) =>
            eachDialCode.value === this.selectedDialCodeDtls.code
        )?.name || '';
      this.selectedDialCodeDtls.name =
        this.CountryISO[countryFullName as keyof typeof CountryISO];
    })
    console.log(this.selectedDialCodeDtls)

  }
  close(): void {
    this.activeModal.close();
  }

  handleUploadSuccess(event: any): void {
    this.dropImage = event;
    console.log('Upload successful event received:', this.dropImage);
    this.profileForm.get('logo')?.setValue(this.dropImage); // Set the logo value
    this.profileForm.get('logo')?.updateValueAndValidity();
    this.imgUpProgress = false;
  }

  handleRemoveImg(event: any): void {
    this.dropImage = this.dropImage?.filter((image: any) => image.name !== event.name) || null;
    console.log('Updated dropImage array:', this.dropImage);
    if (!this.dropImage?.length) {
      this.profileForm.get('logo')?.setValue(null); // Set logo to null if no image is left
      this.profileForm.get('logo')?.updateValueAndValidity(); // Update validity to trigger errors if any
    }
    this.imgUpProgress = false;
  }
  uploadProgress(event:any){
    this.imgUpProgress = true;
    }
  async onSubmit(): Promise<void> {

    const minLength = 4;
    const maxLength = 20;
    const phoneNumberControl = this.profileForm.get('phoneNumber');
    
    // Extract and clean the phone number value
    let phoneNumber = phoneNumberControl?.value?.number || '';
    phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
  
    // Check for required and length validation
    if (phoneNumber.length === 0) {
      phoneNumberControl?.setErrors({ required: true });
    } else if (phoneNumber.length < minLength || phoneNumber.length > maxLength) {
      phoneNumberControl?.setErrors({ invalidLength: true });
    } else {
      phoneNumberControl?.setErrors(null); // Clear any existing errors if valid
    }
    if (this.profileForm.valid) {
      // console.log(this.profileForm)
      this.loading = true;
      const phone = this.profileForm.value.phoneNumber;
      const internationalNumberCode = phone.internationalNumber?.split(' ');
      const formData = new FormData();
      formData.append('firstName', this.profileForm.value.firstName);
      formData.append(
        'phone',
        phone.e164Number!.replace(
          new RegExp(`^\\${internationalNumberCode[0]}`),
          ''
        )
      );
      formData.append('countryCode', phone.dialCode || '');


      // formData.append('primaryColor', this.profileForm.value.primaryColor || '');
      // formData.append('secondaryColor', this.profileForm.value.secondaryColor || '');

      if (this.userDetails.role_id !== 1 && this.userDetails?.billingAddress) {
        formData.append('primaryColor', this.profileForm.value.primaryColor || '');
        formData.append('secondaryColor', this.profileForm.value.secondaryColor || '');
        formData.append('apartment', this.profileForm.value.apartment || '');
        formData.append('address', this.profileForm.value.address || '');
        formData.append('country', this.profileForm.value.country.country || '');
        formData.append('city', this.profileForm.value.city || '');
        formData.append('state', this.profileForm.value.state || '');
        formData.append('pinCode', this.profileForm.value.pinCode || '');
        if (this.dropImage) {
          formData.append('logo', this.dropImage);
        } else {
          formData.append('removeLogo', 'true');
        }
      }
      if(this.userDetails.role_id !== 1 && !this.userDetails?.billingAddress){
        formData.append('primaryColor', this.profileForm.value.primaryColor || '');
        formData.append('secondaryColor', this.profileForm.value.secondaryColor || '');
        if (this.dropImage) {
          formData.append('logo', this.dropImage);
        } else {
          formData.append('removeLogo', 'true');
        }
      }

      if (this.profileForm.value.lastName) {
        formData.append('lastName', this.profileForm.value.lastName);
      }
// console.log(formData)
      this.profileService
        .updateProfile(formData)
        .subscribe((value: any) => {
          if (value.success && value.statusCode === 200) {
            this.loading = false
            this.toaster.success(value.message, "Success", {
              progressBar: true,
              // toastClass: "toast ngx-toastr",
              closeButton: true,
              timeOut: 3000,
            });
            this.activeModal.close();
            this.profileEdited.emit();
            window.location.reload();
          }
          this.loading = false;
        }, (err: any) => {
          this.toaster.error(err?.error?.message || err || "Something went wrong", "Error!", {
            progressBar: true,
            // toastClass: "toast ngx-toastr",
            closeButton: true,
            timeOut: 3000,
          });
          this.loading = false;
        });
      // .subscribe({
      //   next: (value: any) => {
      //     if (value.success && value.statusCode === 200) {
      //       this.loading = false
      //       this.toaster.success(value.message);
      //       this.activeModal.close();
      //       this.profileEdited.emit();
      //     }
      //     this.loading = false;
      //   },
      //   error: (err: any) => {
      //     this.toaster.error(err);
      //     this.loading = false;
      //   },
      // });
    } else {
      this.profileForm.markAllAsTouched();
    }
  }

  onColorChange(controlName: string): void {
    const colorValue = this.profileForm.get(controlName)?.value;
    this.profileForm.get(controlName)?.setValue(colorValue, { emitEvent: false });
  }

  onColorSelect(controlName: string, colorValue: string): void {
    this.profileForm.get(controlName)?.setValue(colorValue);
  }
}
