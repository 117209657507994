import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangeData } from 'ngx-intl-tel-input';
import { PaginationInstance } from 'ngx-pagination';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class WebsiteService {
  constructor(private http: HttpClient) {}
  private handleError(error: HttpErrorResponse) {
    console.log("neweroor", error.error.message)

    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
    } else if (error.error.message) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorMessage = error.error.message;
    } else {
      errorMessage = error.error.error;
    }
    console.log("neweroor2", errorMessage)

    return throwError(errorMessage);
  }
  getWebsites(
    paginationConfig: PaginationInstance,
    filterConfig: any
  ): Observable<any> {
    let params = new HttpParams()
      .append('page', paginationConfig.currentPage)
      .append('limit', paginationConfig.itemsPerPage)
      .append('sortBy', filterConfig.sortColumn)
      .append('sortOrder', filterConfig.sortDirection);

    if (filterConfig.search) {
      params = params.append('searchTerm', filterConfig.search);
    }

    const startDate = filterConfig.selectedDateRange?.start;
    const endDate = filterConfig.selectedDateRange?.end;
    if (startDate) {
      params = params.append('startDate', startDate.format('YYYY/MM/DD'));
    }
    if (endDate) {
      params = params.append('endDate', endDate.format('YYYY/MM/DD'));
    }

    return this.http.get(`${environment.apiHost}api/v1/website/`, {
      params: params,
    });
  }

  getWebsiteList(): Observable<any> {
    return this.http.get(`${environment.apiHost}api/v1/website/list`);
  }

  getChartList(payload: any): Observable<any> {
    return this.http.get(
      `${environment.apiHost}api/v1/statistics?websiteId=${payload.websiteId}&interval=${payload.interval}`
    );
  }

  getWebsite(websiteId: string): Observable<any> {
    return this.http.get(`${environment.apiHost}api/v1/website/${websiteId}`);
  }

  addWebsite(websiteData: any): Observable<any> {
    return this.http.post(
      `${environment.apiHost}api/v1/website/`,
      this.getAddWebsiteFormData(websiteData)
    );
  }
  addBillingAddress(billingAddress: any): Observable<any> {
    return this.http.post(
      `${environment.apiHost}api/v1/user/billing-address`,
      this.getAddWebsiteFormData(billingAddress)
    );
  }

  editWebsite(websiteData: any, websiteId: string): Observable<any> {
    return this.http.patch(
      `${environment.apiHost}api/v1/website/${websiteId}`,
      this.getAddWebsiteFormData(websiteData)
    );
  }

  deleteWebsite(websiteId: string): Observable<any> {
    return this.http.delete(
      `${environment.apiHost}api/v1/website/${websiteId}`
    );
  }

  getWebsiteReport(websiteId: string): Observable<any> {
    return this.http.get(
      `${environment.apiHost}api/v1/website/download-report/${websiteId}`
    );
  }

  getAddWebsiteFormData(websiteData: any): FormData {
    const formData = new FormData();
    for (const value of Object.entries(websiteData)) {
      if (value[1] === null || value[1] === undefined) {
        continue;
      }

      if (value[0] === '_phone_dialcode') {
        const phone = value[1] as ChangeData;
        const internationalNumberCode =
          phone.internationalNumber?.split(' ')[0];
        formData.append(
          'client_phone',
          phone.e164Number!.replace(
            new RegExp(`^\\${internationalNumberCode!}`),
            ''
          )
        );
        formData.append('country_code', phone.dialCode!);
      } else {
        formData.append(value[0], value[1].toString());
      }
    }

    return formData;
  }
  getAddWebsiteFormDataSeperate(websiteData: any): FormData {
    const formData = new FormData();
    for (const value of Object.entries(websiteData)) {
      if (value[1] === null || value[1] === undefined) {
        continue;
      }

      if (value[0] === '_phone_dialcode') {
        const phone = value[1] as ChangeData;
        const internationalNumberCode =
          phone.internationalNumber?.split(' ')[0];
        formData.append(
          'clientPhone',
          phone.e164Number!.replace(
            new RegExp(`^\\${internationalNumberCode!}`),
            ''
          )
        );
        formData.append('countryCode', phone.dialCode!);
      } else {
        formData.append(value[0], value[1].toString());
      }
    }

    return formData;
  }
  getFileNameFromUrl(url: string): string {
    let fileName = 'download';
    const urlPattern = /\/([^\/?#]+)[^\/]*$/;
    const fileNamePattern = /([\w]+)(\.)(.*?)(_DOWNLOAD)/;

    const urlMatch = url.match(urlPattern);
    if (urlMatch) {
      const fileNameMatch = urlMatch[1].match(fileNamePattern);
      fileName = fileNameMatch ? fileNameMatch[3] : 'download';
    }
    return `${fileName}.pdf`;
  }

  checkWebsitesExists(): Observable<any> {
    return this.http.get(`${environment.apiHost}api/v1/website/first`);
  }

  websiteExistCheck(payload:any): Observable<any> {
    const url = `${environment.apiHost}api/v1/website/exist`;
    return this.http.post<any>(url, payload);
  }

addWebsiteWithToken(payload:any): Observable<any> {
  const url = `${environment.apiHost}api/v1/website/create-with-token`;
  return this.http.post<any>(url, this.getAddWebsiteFormDataSeperate(payload));
}
  cancelSubscriptionByWebsiteId(websiteid: any): Observable<any> {
    const url = `${environment.apiHost}api/v1/website/cancel-subscription`;
    return this.http.post<any>(url, websiteid).pipe(catchError(this.handleError));
  }
  
  getClientSecret(): Observable<any> {
    const url = `${environment.apiHost}api/v1/website/create-setup-intent`;
    return this.http.post<any>(url,'');
  }
  AddwebsiteWithSubscription(payload:any): Observable<any> {
    const url = `${environment.apiHost}api/v1/website/create-with-subscription`;
    return this.http.post<any>(url,this.getAddWebsiteFormDataSeperate(payload));
  }
}

