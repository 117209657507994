<div class="modal-content">
    <div class="modal-header">
       
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
        <img src="../../../assets/img/modal-close.svg" alt="">
    </button>
    </div>
    <div class="modal-body">
        <div class="success-ico">
            <img src="../../../assets/img/delete.png" alt="success">
        </div>
       <h2 class="modal-title">Are you sure want to delete
        <p class="fs-5">{{title}}?</p>
       </h2>
        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteItem()" [disabled]="loading">
          <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                    <span *ngIf="!loading">Delete</span></button>
    </div>
</div>
