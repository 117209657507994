import { Component, Output, EventEmitter, ViewChild, Input, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface, } from 'ngx-dropzone-wrapper';
import { Subscription } from 'rxjs';
import { DropzoneService } from '../../modules/service/dropzone.service';

@Component({
  selector: 'app-image-dropzone',
  templateUrl: './image-dropzone.component.html',
  styleUrl: './image-dropzone.component.scss'
})
export class ImageDropzoneComponent implements AfterViewInit, OnInit {
  @Output() uploadSuccess: EventEmitter<any> = new EventEmitter();

  uploadedImage: string | ArrayBuffer | null = null;
  uploadedFile: File | null = null;
  @Input() imageUrl: any;
  @Input() signup: any=false;
  @Input() submitId: any;
  @Output() removeImg: EventEmitter<any> = new EventEmitter();
  @Output() UploadProgress: EventEmitter<any> = new EventEmitter();
  @Output() isRemoved: EventEmitter<any> = new EventEmitter();
  @ViewChild(DropzoneComponent, { static: false }) componentRef?: DropzoneComponent;
  @Input() multiplefile: boolean = false;
  imageOnly: any = 'image/jpeg,image/jpg,image/png,image/gif,image/bmp,image/svg+xml,image/heic,image/heif'
  imageAndOtherFile: any = 'image/*,image/bmp,image/gif,image/heif,image/heic,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
  imageUpd = true;
  public config: DropzoneConfigInterface = {
    maxFilesize: 10,
    acceptedFiles: this.imageOnly,
    maxFiles: 1,
    uploadMultiple: false,
    addRemoveLinks: true,
    parallelUploads: 1,
    dictDefaultMessage: "Click or drag files to upload( max 10 MB)",

  };



  constructor(private dropzoneservice: DropzoneService) { }
  ngAfterViewInit(): void {

  }


  public onDropzoneInit(dropzone: any): void {
    const mockFile = { name: this.imageUrl.name, size: null, dataURL: this.imageUrl.url, isMock: true };

    dropzone.files.push(mockFile);

    dropzone.emit('addedfile', mockFile);

    dropzone.createThumbnailFromUrl(
      mockFile, 200, null, 'contain', true,
      function (thumbnail: any) {
        dropzone.emit('thumbnail', mockFile, thumbnail);
      },
      'anonymous'
    );


    dropzone.emit('complete', mockFile);

    dropzone.on("removedfile", (file: any) => {
    
      this.onRemoveFile(file)


    });


  }


  onRemoveFile(event: any) {
      this.removeImg.emit(event)
  }

  ngOnInit(): void {
    this.config.uploadMultiple = this.multiplefile;
    this.config.maxFiles = this.multiplefile ? 5 : 1;
    this.config.acceptedFiles = this.multiplefile ? this.imageAndOtherFile : this.imageOnly;
    console.log(this.imageUrl)

  }

  maxFilesReached(files: any): void {

    if (files.length > 1) {
      this.componentRef?.directiveRef?.dropzone().removeFile(files[0])
    }
  }


  onUploadProgress(event: any): void {
    const progress = event[1];  // event[1] contains the upload progress percentage
    console.log(`Uploading... ${progress}%`);
    this.UploadProgress.emit()
    // You can update a progress bar or any UI element based on the progress
  }
  
  onUploadSuccess(event: any): void {
    console.log('Successfully uploaded:', event);
    let file = event[0];
    this.imageUpd = false;
    if (file.type.startsWith('image/')) {
      let dropImage = this.base64ToFile(file.dataURL, file.name);
        this.uploadedImage = file.dataURL;  // Display the uploaded image
        this.uploadedFile = dropImage;
        if(this.signup){
          let data = {
            file:file.dataURL,
            name: file.name,
            dropImage:dropImage
          }
          this.uploadSuccess.emit(data);
        } else {
        this.uploadSuccess.emit(dropImage);
      }
    } else {
      this.uploadedFile = file;
      this.uploadSuccess.emit(file);
    }
  }

  onUploadError(event: any): void {
    console.log('Error during upload:', event);
    this.UploadProgress.emit()

  }

  base64ToFile(base64String: string, fileName: string): File {
    // Remove the data URL scheme part if present
    const base64Pattern = /^data:(.*?);base64,/;
    const base64Data = base64String.replace(base64Pattern, '');

    // Convert base64 string to a binary string
    const binaryString = atob(base64Data);

    // Create a byte array
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: 'image/png' });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: 'image/png' });

    return file;
  }

  cancelUpload(): void {
    // Clear uploaded image and file

    this.uploadedImage = null;
    this.uploadedFile = null;
  }



}
