<div class="dropzone-container" *ngIf="imageUrl.url == null || imageUrl.url == ''">
  <dropzone  [config]="config" (success)="onUploadSuccess($event)" (error)="onUploadError($event)" (removedFile)="onRemoveFile($event)"  (sending)="onUploadProgress($event)">

  </dropzone>
  <div >

  </div>
</div>
<div class="dropzone-container" *ngIf="imageUrl.url !== null && imageUrl.url !== ''">
  <dropzone
      [config]="config"
      (success)="onUploadSuccess($event)"
      (error)="onUploadError($event)"
      (init)="onDropzoneInit($event)"
      (maxFilesReached)="maxFilesReached($event)"
(removedFile)="onRemoveFile($event)"
    ></dropzone
  >
  <div >


  </div>
</div>
