import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../modules/service/auth-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutComponent } from './logout/logout.component';
import { ProfileService } from '../../modules/service/profile-service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @Output() titleClicked = new EventEmitter<any>();
  @Output() sidebarClicked: EventEmitter<boolean> = new EventEmitter();
  @Input() sidebarStatus: boolean = false;
  @Input() sideBar: any;
  navigateText = '';

  isVerified!: boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActiveMenuItem(this.router.url);
      }
    });
    this.setActiveMenuItem(this.router.url);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sideBar']) {
      this.setActiveMenuItem(this.router.url);
    }

    this.profileService.isVerified$.subscribe(status => {
      this.isVerified = status;
    });

  }

  setActiveLink(): void {
    const links = document.querySelectorAll('.nav-link');
    links.forEach(link => {
      link.classList.remove('active');
      if (link.getAttribute('href') === this.router.url) {
        link.classList.add('active');
      }
    });
  }

  redirect(url: any) {
    this.sideBar.map((res: any) => {
      res.isActive = false;
    });
    url.isActive = !url.isActive;
    if (url.name == 'Dashboard' || url.name == 'My Account' || url.name == 'Statistics' || url.name == 'User management' || url.name == 'Transaction management' || url.name == 'Activation code' || url.name == 'Websites') {
      this.router.navigate([url.rederict]);
    } else {
      this.router.navigate([url.rederict], {
        queryParams: { page: 1, search: '', size: 10 }
      });
    }
    this.titleClicked.emit(url.name);

    if (window.innerWidth <= 768) {
      this.sidebarStatus = false;
      this.sidebarClicked.emit(false);
    }
  }

  onLogout() {
    const modalRef = this.modalService.open(LogoutComponent, {
      scrollable: true,
      backdrop: 'static',
      windowClass: 'modal-small-width modal-micro fade modal-logout',
      centered: true
    });
  }

  private getBaseUrl(url: string): string {
    return url.split('?')[0].split('#')[0];
  }

  private setActiveMenuItem(url: string) {
    const baseUrl = this.getBaseUrl(url);
    this.sideBar.map((res: any) => {
      res.isActive = false;
    });

    if (baseUrl.includes('admin')) {
      if (baseUrl.includes('dashboard')) {
        this.sideBar[0].isActive = true;
        this.navigateText = 'Dashboard';
      } else if (baseUrl.includes('user-manage')) {
        this.sideBar[1].isActive = true;
        this.navigateText = 'User management';
      } else if (baseUrl.includes('activation-code')) {
        this.sideBar[3].isActive = true;
        this.navigateText = 'Activation code';
      } else if (baseUrl.includes('transaction-manage')) {
        this.sideBar[2].isActive = true;
        this.navigateText = 'Transaction management';
      } else if (baseUrl.includes('my-profile')) {
        this.sideBar[4].isActive = true;
        this.navigateText = 'My Account';
      } else if (baseUrl.includes('user-detail')) {
        this.sideBar[1].isActive = true;
        this.navigateText = 'User management';
      }
      this.navigateText = 'dashboard';
    } else {
      if (baseUrl.includes('statistics')) {
        this.navigateText = 'Statistics';
        this.sideBar[0].isActive = true;
      } else if (baseUrl.includes('my-profile')) {
        this.navigateText = 'My Account';
        this.sideBar[2].isActive = true;
      } else if (baseUrl.includes('website-list')) {
        this.navigateText = 'Websites';
        this.sideBar[1].isActive = true;
      }
    }
  }
}
