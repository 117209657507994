import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivationService } from '../../modules/service/activation.service';
import { WebsiteService } from '../../modules/service/website/website.service';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrl: './delete-popup.component.scss'
})
export class DeletePopupComponent {
  loading:boolean = false;
  @Input() activationId:any;
  @Output() deleted = new EventEmitter<any>();

  @Input() websiteId?: string;
  @Input() title: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private activationService: ActivationService,
    private websiteService: WebsiteService
  ) {}

  close(): void {
    this.activeModal.close();
  }

  deleteItem(){
    this.loading = true;
    if(this.activationId){
      this.activationService.deleteActivationCode(this.activationId).subscribe(res =>{
      if(res.success){
        this.loading = false;
        this.activeModal.close();
        this.toastrService.success(res.message, "Success", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
          });
          this.deleted.emit("")
      }
    },(err:any)=>{
      this.loading = false;
      this.toastrService.error(err.error.message, "Error!", {
        progressBar: true,
        // toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 3000,
        });
    })
    } else if(this.websiteId) {
      this.websiteService.deleteWebsite(this.websiteId).subscribe({
        next: (res) => {
          if (res.success && res.statusCode === 200) {
            this.toastrService.success(res.message);
            this.deleted.emit();
          }
          this.loading = false;
          this.close();
        },
        error: (err) => {
          this.toastrService.error(err.error.message);
          this.loading = false;
        },
      });
    } else{
      this.loading = false;
      this.close();
      this.deleted.emit();
    }
  }
}
