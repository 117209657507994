<div class="modal modal-small-width modal-micro fade modal-subscribe-success cancel-subscription" id="subscribedSuccess" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="activationCodeLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> 
      <div class="modal-content">
        <div class="modal-header">
           
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="../../../assets/img/modal-close.svg" alt=""></button>
        </div>
        <div class="modal-body">
            <div class="success-ico">
                <img src="../../../assets/img/popup-diamond.png" alt="success">
            </div>
           <div class="plan-info1">
                <div class="plan-detial1">
                    <div class="subtitle">Plan</div>
                    <div class="content">SEO Backlink Service</div>
                </div>
                <div class="plan-inner">
                    <div class="plan-detial">
                        <div class="subtitle">Plan purchase</div>
                        <div class="content">18 June 2024</div>
                    </div>
                    <div class="plan-detial">
                        <div class="subtitle">Plan expiry</div>
                        <div class="content">18 August 2024</div>
                    </div>
                </div>
                <div class="plan-inner">
                    <div class="plan-detial">
                        <div class="subtitle">Amount</div>
                        <div class="content">$250 /Mo</div>
                    </div>
                    <div class="plan-detial">
                        <div class="subtitle">Renewal Date</div>
                        <div class="content">19 August 2024</div>
                    </div>
                </div>
           </div>
        </div>
        <div class="modal-footer">
            <div class="ok-btn">
                <a href="">Cancel Subscription</a>
            </div>
        </div>
      </div>
    </div>
</div>
