import { Component, EventEmitter, Output,Input, OnInit} from '@angular/core';
import { ProfileService } from '../../modules/service/profile-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrl: './profile-card.component.scss'
})
export class ProfileCardComponent {
  @Output() iseditClicked = new EventEmitter<any>();
  @Output() isresetClicked = new EventEmitter<any>();

  @Input() userData: any = '';

  editProfile(){
    this.iseditClicked.emit('edit');

   }

   resetPassword()
   {
    this.isresetClicked.emit('reset');
   }
}
