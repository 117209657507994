import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environment/environment';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  isLoggedIn: boolean = false;
  userProfile: any;

  private profile = new BehaviorSubject<any>(null);
  profile$ = this.profile.asObservable();


  private isVerifiedSubject = new BehaviorSubject<boolean>(false); // Initialize with a default value
  isVerified$ = this.isVerifiedSubject.asObservable();

  setIsVerified(status: boolean) {
    this.isVerifiedSubject.next(status);
  }

  constructor(private http: HttpClient,) { }

  isSessionActive() {
    this.isLoggedIn = true;
    return localStorage.getItem('token');
  }
  private handleError(error: HttpErrorResponse) {
    console.log("neweroor", error.error.message)

    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
    } else if (error.error.message) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorMessage = error.error.message;
    } else {
      errorMessage = error.error.error;
    }

    return throwError(errorMessage);
  }


  getProfile(id: any) {
    return this.http.get(`${environment.apiHost}api/v1/user/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  resetPassword(payload: any) {
    return this.http.put(`${environment.apiHost}api/v1/user/change-password`, payload)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateProfile(payload: any) {
    return this.http.put(`${environment.apiHost}api/v1/user`, payload).pipe(
      catchError(this.handleError)
    );
  }
  getAllUser(payload: any, filter?: any) {
    let api: any;
    if (filter !== undefined) {
      api = `api/v1/user?page=${payload.pageNumber}&limit=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}&search=${payload.search}&joinStartDate=${filter.joiningDateFrom ? filter.joiningDateFrom : ''}&joinEndDate=${filter.joiningDateTo ? filter.joiningDateTo : ''}&planId=${filter.plan ? filter.plan : ''}&planStatus=${filter.planStatus ? filter.planStatus : ''}&userStatus=${filter.userStatus ? filter.userStatus : ''}&planPurchaseStartDate=${filter.planDateFrom ? filter.planDateFrom : ''}&planPurchaseEndDate=${filter.planDateTo ? filter.planDateTo : ''}&expiryStartDate=${filter.expiryDateFrom ? filter.expiryDateFrom : ''}&expiryEndDate=${filter.expiryDateTo ? filter.expiryDateTo : ''}`
    } else {
      api = `api/v1/user?page=${payload.pageNumber}&limit=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}&search=${payload.search}&joinStartDate=&joinEndDate=`
    }
    return this.http.get(`${environment.apiHost}${api}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  userBlocked(payload: any) {
    return this.http.post(`${environment.apiHost}api/v1/user/${payload.id}/status/${payload.status}`, {})
      .pipe(
        catchError(this.handleError)
      );
  }
  getDialCode() {
    return this.http.get('assets/dial_code.json');
  }
  getCurrentUser() {
    return this.http.get(`${environment.apiHost}api/v1/user/profile/current`)
      .pipe(
        tap((res: any) => {
          if (res.success && res.statusCode === 200) {
            this.profile.next(res.user);
          }
        }),
        catchError(this.handleError)
      );
  }

  verifyEmail(email: any): Observable<any> {
    let payload = {
      email: email
    }
    return this.http.post<any>(environment.apiHost + `api/v1/auth/email-verification`, payload);
  }

  verifyToken(token: any): Observable<any> {
    let payload = {
      token: token
    }
    return this.http.post<any>(environment.apiHost + `api/v1/auth/verify-token`, payload);
  }

  isActive(value: any): boolean {
    let status = false;

    // if (value) {
    //   if (value.plan && value.plan.type === 1) {
    //     status = true;
    //   } else if (
    //     value.subscription &&
    //     value.subscription.length !== 0 &&
    //     value.subscription[0].status === 'active'
    //   ) {
    //     status = true;
    //   }
    // }

    return true;
  }
}
