import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../../modules/service/profile-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  headerTitle: string = ""
  loading: boolean = false;
  @Input() sideBar = false;
  @Input() userData: any;
  userData1: any;
  @Output() sidebarClicked: EventEmitter<any> = new EventEmitter();

  isVerified: boolean = true;
  verifyLoading: boolean = false;

  constructor(
    private router: Router,
    private profile: ProfileService,
    private toastrService: ToastrService) {
    this.getUserDtl()
  }
  getUserDtl() {
    this.loading = true;
    this.profile.getCurrentUser().subscribe((res: any) => {
      if (res.success) {
        this.loading = false;
        this.userData1 = res.user
        this.isVerified = res.user.is_verified;

        this.profile.setIsVerified(this.isVerified);

        this.profile.userProfile = res.user
        document.documentElement.style.setProperty('--sidebarPrimary', res.user.primary_color ? res.user.primary_color : '#34ca00');
        document.documentElement.style.setProperty('--headerPrimary', res.user.primary_color ? res.user.primary_color : '#fff');
        document.documentElement.style.setProperty('--sidebarSecondary', res.user.secondary_color ? res.user.secondary_color : '#65656538');
      }
    }, (err: any) => {
      this.toastrService.error(err.error.message, "Error!", {
        progressBar: true,
        closeButton: true,
        timeOut: 3000,
      });
      this.loading = false;
    })
  }
  setHeaderTitle() {
    switch (true) {
      case this.router.url.includes('dashboard'):
        return 'Dashboard';

      case this.router.url.includes('user-manage'):
        return 'User Management';

      case this.router.url.includes('statistics'):
        return 'Statistics';

      case this.router.url.includes('website-list'):
        return 'Websites';

      case this.router.url.includes('my-profile'):
        return 'My Account';

      case this.router.url.includes('transaction-manage'):
        return 'Transaction Management';

      case this.router.url.includes('activation-code'):
        return 'Activation Code';
      default:
        return '';
    }
  }

  sidebar() {
    this.sideBar = !this.sideBar
    this.sidebarClicked.emit(this.sideBar)
  }

  onVerifyNow() {
    this.verifyLoading = true;
    this.profile.verifyEmail(this.userData1.email).subscribe({
      next: (res) => {
        if (res.success) {
          this.toastrService.success(res.message, 'Success', {
            progressBar: true,
            closeButton: true,
            timeOut: 3000,
          });
        }
        this.verifyLoading = false;
      },
      error: (err: any) => {
        this.toastrService.error(err.error.message, 'Error!', {
          progressBar: true,
          closeButton: true,
          timeOut: 3000,
        });
        this.verifyLoading = false;
      },
    });
  }
}
