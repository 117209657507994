<ng-container >
    @if(group){
      @if(group.errors && group.errors[validatorName] && (
        group.get(controlName)?.touched || group.get(controlName)?.dirty)){
          <div  class="form-error">
          {{customMessage}}
        </div>
      }
    }
  </ng-container>
  
  <ng-container >
    @if(!group){
      @if(control.errors && control.errors[validatorName] && (checkTouchedDirtyCtrl())){
        <div class="form-error">
            <ng-content></ng-content>
        </div>
      }
    }
  </ng-container>