import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environment/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn: boolean = false;
  userProfile: any;
  constructor(private http: HttpClient, private router: Router) { }

  private handleError(error: HttpErrorResponse) {
    console.log("neweroor", error.error.message)

    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
    } else if (error.error.message) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorMessage = error.error.message;
    } else {
      errorMessage = error.error.error;
    }
    console.log("neweroor2", errorMessage)

    return throwError(errorMessage);
  }

  isSessionActive() {
    this.isLoggedIn = true;
    return localStorage.getItem('token');
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiresIn');
    localStorage.clear()
    this.router.navigate(['/']);
  }

  login(data: any): Observable<any> {
    return this.http.post<any>(environment.apiHost + 'api/v1/auth/login', data);
  }

  Contactus(data: any): Observable<any> {
    return this.http.post<any>(environment.apiHost + 'api/v1/contact-us', data);
  }

  verifyEmail(payload: any): Observable<any> {
    const url = `${environment.apiHost}api/v1/auth/check-email`;
    return this.http.post<any>(url, payload).pipe(catchError(this.handleError));
  }
  getCurrentUserSignup(token: any) {
    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: `bearer ${token}`,
        "Content-Type": "application/json",
      };
      this.http
        .get(`${environment.apiHost}api/v1/user/profile/current`, {
          headers: headers,
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
    // return this.http.get(`${environment.apiHost}api/v1/user/profile/current`, {
    //   withCredentials: true,
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // })
  }

  addPersonalInfoDetails(payload: any): Observable<any> {
    const url = `${environment.apiHost}api/v1/auth/signup`;
    return this.http.post<any>(url, payload).pipe(catchError(this.handleError));
  }

  signupWithCode(payload: any): Observable<any> {
    const url = `${environment.apiHost}api/v1/auth/signup-with-token`;
    return this.http.post<any>(url, payload).pipe(catchError(this.handleError));
  }

  getSubscriptionPlanList(data: any) {
    return this.http.get<any>(`${environment.apiHost}api/v1/plans?filter=${data}`)
      .pipe(
        catchError(this.handleError)
      );
  }
  getSubscriptionPlanList1() {
    return this.http.get(`${environment.apiHost}api/v1/plans`)
      .pipe(
        catchError(this.handleError)
      );
  }
  cancelSubcriptionPlan() {
    return this.http.delete(`${environment.apiHost}api/v1/subscription/cancel`)
      .pipe(
        catchError(this.handleError)
      );
  }
  countryist(): Observable<any> {
    const url = `${environment.apiHost}api/v1/countries`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  applyCoupon(payload: any): Observable<any> {
    const url = `${environment.apiHost}api/v1/subscription/validate-coupon`;
    return this.http.post<any>(url, payload).pipe(catchError(this.handleError));
  }

  changePasswordRequest(email: any): Observable<any> {
    const url = `${environment.apiHost}api/v1/auth/forgot-password`;
    return this.http.post<any>(url, email).pipe(catchError(this.handleError));
  }
  resetPassword(payload: any): Observable<any> {
    const url = `${environment.apiHost}api/v1/auth/reset-password`;
    return this.http.post<any>(url, payload).pipe(catchError(this.handleError));
  }


  
}
