<div class="modal-header">
  <h5 class="modal-title" id="staticBackdropLabel">Edit My Profile</h5>
  <button type="button" class="btn-close" (click)="close()"><img src="../../../assets/img/modal-close.svg"
      alt=""></button>
</div>
<div class="profile-edit-inner">

  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="modal-body modal-form ">
      <div class="row form-inner">
       
        <div [ngClass]="userDetails?.role_id === 1 ? 'col-md-12' : 'col-md-7 left-sec'">
          <div class="heading">
            <span class="imgs1"><img src="../../../../../assets/img/person-modal.svg" alt="" /></span>
            <span class="text">Personal Details</span>
          </div>
          <div class="row">
            
          <div class="form-group input-wrp col-md-6">
            
            <label for="" class="form-label">First name <span class="text-danger"> * </span></label>
            <input type="text" class="form-control" placeholder="First name" id="firstName" formControlName="firstName">

            <div *ngIf="profileForm.get('firstName')?.touched && profileForm.get('firstName')?.invalid"
              class="form-error">
              <small class="text-danger" *ngIf="profileForm.get('firstName')?.errors?.['required']"
                class="err-msg">First Name is required.</small>
              <small class="text-danger" *ngIf="profileForm.get('firstName')?.errors?.['firstName']"
                class="err-msg">Enter valid first name.</small>
            </div>
          </div>

          <div class="form-group input-wrp col-md-6">
            <label for="" class="form-label">Last name<span class="text-danger"> * </span></label>
            <input type="text" class="form-control" placeholder="Enter Last name" id="lastName"
              formControlName="lastName">
            <div *ngIf="profileForm.get('lastName')?.touched && profileForm.get('lastName')?.invalid"
              class="form-error">
              <small class="text-danger" *ngIf="profileForm.get('lastName')?.errors?.['required']" class="err-msg">Last
                Name is required.</small>
              <small class="text-danger" *ngIf="profileForm.get('lastName')?.errors?.['lastName']" class="err-msg">Enter
                valid last name.</small>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-wrp">
              <label for="" class="form-label">E-mail id</label>
              <input type="text" class="form-control" placeholder="Enter email" formControlName="email">
  
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group input-wrp">
              <label for="phoneNumber" class="form-label">Phone number<span class="text-danger">*</span></label>
              <ngx-intl-tel-input class="form-control btm-pos flag-input" [enableAutoCountrySelect]="true"
                [searchCountryFlag]="true" [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name,
                  SearchCountryField.DialCode
                ]" [selectFirstCountry]="false" [selectedCountryISO]="selectedDialCodeDtls.name"
                [enablePlaceholder]="true" customPlaceholder="Phone Number"  [phoneValidation]="false"
                [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National" formControlName="phoneNumber"
                name="phoneNumber"
                (input)="restrictSpecialCharacters($event)">
              </ngx-intl-tel-input>
              <ng-container *ngIf="profileForm.get('phoneNumber')?.invalid">
                <span class="form-error pt-1 err-msg" *ngIf="profileForm.get('phoneNumber')?.errors?.['required']">Phone
                  number is required</span>
                <span class="form-error pt-1 err-msg" *ngIf="profileForm.get('phoneNumber')?.errors?.['invalidLength'] &&
                    !profileForm.get('phoneNumber')?.errors?.['required']
                  ">Enter valid phone number</span>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" *ngIf="userDetails.role_id !== 1">
       
              <label for="primaryColor" class="form-label">Primary Colour <span class="text-danger">*</span> </label>
  
              <div class="colorpick form-control1">
                <div class="color-options">
                  <label class="color-radio" *ngFor="let color of primaryColors">
                    <input type="radio" name="primaryColor" [value]="color"
                      [checked]="profileForm.get('primaryColor')?.value === color"
                      (change)="onColorSelect('primaryColor', color)">
                    <span class="color-preview" [style.backgroundColor]="color"></span>
                  </label>
                </div>
              </div>
              <!-- <div class="colorpick form-control">
                <span class="color-preview primary"
                  [style.backgroundColor]="profileForm.get('primaryColor')?.value"></span>
                <input type="color" class="color-input" id="primaryColor" formControlName="primaryColor"
                  (input)="onColorChange('primaryColor')">
                <span class="value">{{ profileForm.get('primaryColor')?.value }}</span>
              </div> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" *ngIf="userDetails.role_id !== 1">
            <!-- <label for="" class="form-label">Secondary Colour</label>
          <input type="color" class="form-control" placeholder="" value="#C3DEB9" id="secondaryColor"
            formControlName="secondaryColor"> -->

            <!-- <label for="secondaryColor" class="form-label">Secondary Colour</label> -->
            <!-- <div class="colorpick form-control">
              <span class="color-preview secondary"
                [style.backgroundColor]="profileForm.get('secondaryColor')?.value"></span>
              <input type="color" class="color-input" id="secondaryColor" formControlName="secondaryColor"
                (input)="onColorChange('secondaryColor')">
              <span class="value">{{ profileForm.get('secondaryColor')?.value }}</span>
            </div> -->
            <div class="colorpick form-control1">
              <div class="input-wrp">
                <label for="secondaryColor" class="label">Secondary Colour <span class="text-danger">*</span> </label>
                <div class="colorpick form-control1">
                  <div class="color-options">
                    <label class="color-radio" *ngFor="let color of secondaryColors">
                      <input type="radio" name="secondaryColor" [value]="color"
                        [checked]="profileForm.get('secondaryColor')?.value === color"
                        (change)="onColorSelect('secondaryColor', color)">
                      <span class="color-preview" [style.backgroundColor]="color"></span>
                    </label>
                  </div>
                </div>

                <!-- <div class="colorpick form-control">
                    <span class="color-preview secondary"
                        [style.backgroundColor]="personalInfoForm.get('secondaryColor')?.value"></span>
                    <input type="color" class="color-input" id="secondaryColor" formControlName="secondaryColor"
                        (input)="onColorChange('secondaryColor')">
                    <span class="value">{{ personalInfoForm.get('secondaryColor')?.value }}</span>
                </div> -->
              </div>
            </div>
          </div>
          </div>
        </div>
          
          <div class="form-group" *ngIf="userDetails.role_id !== 1">
            <label for="" class="form-label">Logo Upload</label>
            <div class="file-upload-wrp">
              <div *ngIf="!isedit">
                <app-image-dropzone (uploadSuccess)="handleUploadSuccess($event)" (isRemoved)="isAddimgRemove = $event"
                  [imageUrl]="imageData" (removeImg)="handleRemoveImg($event)"  (UploadProgress)="uploadProgress($event)"></app-image-dropzone>
              </div>
              <div *ngIf="isedit">
                <div class="custom-file">
                  <div class="edit-preview">
                    <img [src]="userDetails?.logo">
                    <a class="edit-close" style="cursor: pointer;" (click)="removefile()"><img
                        src="../../../assets/img/close-green.svg" alt="close btn"></a>
                  </div>
                </div>
              </div>
              <!-- <div class="form-error" *ngIf="profileForm.get('logo')?.touched && profileForm.get('logo')?.invalid">
                <small class="err-msg">Logo is required.</small>
              </div> -->
            </div>
          </div>

          
          
          
        </div>
    
        <div class="right-sec" *ngIf="userDetails.role_id !== 1 && userDetails?.billingAddress">
          <div *ngIf="isLoading; else cancelSub" class="skeleton-container">
            <div class="skeleton-card">
              <div class="skeleton-header">
                <div class="skeleton-header-left">
                  <ngx-skeleton-loader class="skeleton-circle" count="1" appearance="circle"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-subtitle" count="1" appearance="line"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-circle" count="1" appearance="circle"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-title" count="1" appearance="line"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-circle" count="1" appearance="circle"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-subtitle " count="1" appearance="line"></ngx-skeleton-loader>

                  <ngx-skeleton-loader class="skeleton-subtitle mt" count="1" appearance="line"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-subtitle" count="1" appearance="line"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-subtitle" count="1" appearance="line"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="skeleton-subtitle" count="1" appearance="line"></ngx-skeleton-loader>




                  
    
                </div>
              </div>
            </div>
    
            
          </div>
          <ng-template #cancelSub>
          <div class="heading">
            <span class="imgs1"><img src="../../../../../assets/img/billing.svg" alt="" /></span>
            <span class="text">Billing Address</span>
          </div>
          <div class="row">
            <div class="form-group input-wrp col-md-6">
              <label for="" class="form-label">City <span class="text-danger"> * </span></label>
              <input type="text" class="form-control" placeholder="Enter City" id="city" formControlName="city">
  
              <div *ngIf="profileForm.get('city')?.touched && profileForm.get('city')?.invalid"
                class="form-error">
                <small class="text-danger" class="err-msg">
                  {{getCityError(profileForm.get('city'))}}
                </small>
              </div>
            </div>
  
            <div class="form-group input-wrp col-md-6">
              <label for="" class="form-label">State<span class="text-danger"> * </span></label>
              <input type="text" class="form-control" placeholder="Enter State" id="state"
                formControlName="state">
              <div *ngIf="profileForm.get('state')?.touched && profileForm.get('state')?.invalid"
                class="form-error">
                <small class="text-danger">{{getStateError(profileForm.get('state'))}}</small>
              </div>
            </div>
  
          </div>

          <div class="row">
            <div class="form-group input-wrp col-md-6">
              <label for="" class="form-label">Postal Code<span class="text-danger"> * </span></label>
              <input type="text" class="form-control" placeholder="Enter Postal Code" id="pinCode" formControlName="pinCode">
  
              <div *ngIf="profileForm.get('pinCode')?.touched && profileForm.get('pinCode')?.invalid"
                class="form-error">
                <small class="text-danger" class="err-msg">
                  {{getPinCodeError(profileForm.get('pinCode'))}}
                </small>
              </div>
            </div>
  
            <div class="form-group input-wrp col-md-6">
              <label for="" class="form-label">Apartment/Suite<span class="text-danger"> * </span></label>
              <input type="text" class="form-control" placeholder="Enter Apartment/Suite" id="apartment"
                formControlName="apartment">
              <div *ngIf="profileForm.get('apartment')?.touched && profileForm.get('apartment')?.invalid"
                class="form-error">
                <small class="text-danger" class="err-msg">
                  {{getAppartMentError(profileForm.get('apartment'))}}
                </small>
              </div>
            </div>
  
          </div>
          <div class="row">
  
            <div class="form-group input-wrp angular-select week-select col-md-6">
              <label for="country" class="label">Country <span
                class="text-danger">*</span></label>
              <ng-select class="form-control" name="country" id="country"
                  #ngSelectComponentInterval class="angular-select" [items]="country"
                  bindLabel="country"
                  placeholder="Select country" formControlName="country"
                  [clearable]="true" [editableSearchTerm]="true">
              </ng-select>
              <div *ngIf="profileForm.get('country')?.touched && profileForm.get('country')?.invalid"
                class="form-error">
                <small class="text-danger" *ngIf="profileForm.get('country')?.errors?.['required']" class="err-msg">
                  Country is required.</small>
                  <small class="text-danger" *ngIf="profileForm.get('city')?.errors?.['lettersOnly']"
                  class="err-msg">Enter valid city.</small>
            </div>
  
          </div>
        </div>
          <div class="form-group input-wrp">
            <label for="address" class="form-label">Address <span class="text-danger"> * </span></label>
            <textarea id="address" class="form-control profile-edit-area" formControlName="address" ></textarea>
            <div *ngIf="profileForm.get('address')?.touched && profileForm.get('address')?.invalid"
                class="form-error">
                <small class="text-danger" *ngIf="profileForm.get('address')?.errors?.['required']" class="err-msg">
                  Address is required.</small>
                <small class="text-danger" *ngIf="profileForm.get('address')?.errors?.['trimError']" class="err-msg">
                  Address details cannot start with a space or be just spaces.
                </small>
              </div>
          </div>
        </ng-template>
          
          </div>
          
        </div>

       

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="loading || imgUpProgress">
        <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
        <span *ngIf="!loading">Submit</span>
      </button>
    </div>
  </form>