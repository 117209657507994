<div class="account-action-top">
    
  <div class="profile-info-acc">
    <!-- <div class="prof-img-wrp">
      <img src="../../../../assets/img/my-acc-usr-img.png" alt="User Image" class="prof-img">
  </div> -->
      <div class="prf-dtl">
          <span class="prof-name">{{userData?.name}}</span>
          <h5 class="prof-desg">{{userData?.email}}</h5>
      </div>
  </div>
  <div class="action-btn-blks">
      <a class="prof-btns"  (click)="resetPassword()">
          <span class="icon">
              <img src="../../../../assets/img/reset-password.svg" alt="">
          </span>
          <span class="txt">Reset Password</span>
      </a>
      <a class="prof-btns" type="button" (click)="editProfile()">
          <span class="icon">
              <img src="../../../../assets/img/edit-profile.svg" alt="">
          </span>
          <span class="txt">Edit Profile</span>
      </a>
  </div>
</div>
