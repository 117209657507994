import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './side-bar/side-bar.component';
import { HeaderComponent } from './header/header.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LogoutComponent } from './side-bar/logout/logout.component';
import { ImageDropzoneComponent } from './image-dropzone/image-dropzone.component';


import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormErrorComponent } from './form-error/form-error.component';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaymentpopupComponent } from './paymentpopup/paymentpopup.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentWebsiteComponent } from './payment-website/payment-website.component';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*',

};

@NgModule({
  declarations: [
    SideBarComponent,
    HeaderComponent,
    ProfileCardComponent,
    EditProfileComponent,
    ResetPasswordComponent,
    LogoutComponent,
    ImageDropzoneComponent,
    FormErrorComponent,
    DeletePopupComponent,
    PaymentpopupComponent,
    PaymentWebsiteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    DropzoneModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgxSpinnerModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    SideBarComponent,
    HeaderComponent,
    ProfileCardComponent,
    EditProfileComponent,
    DropzoneModule,
    ImageDropzoneComponent,
    FormErrorComponent,
    NgxSkeletonLoaderModule,
    NgSelectModule,
    PaymentWebsiteComponent
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ],
})
export class SharedModule { }
