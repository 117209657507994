<div class="modal-content">
    <div class="modal-header">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
            <img src="../../../../assets/img/modal-close.svg" alt="">
        </button>
    </div>
    <div class="modal-body">
        <div class="success-ico">
            <img src="../../../../assets/img/logout-warning-icon.png" alt="success">
        </div>
        <h2 class="modal-title">Are You Leaving ?</h2>
        <p class="modal-desc">Are you sure want to logout ? All your <span class="d-block"></span>

            unsaved data will be lost.</p>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onLogout()">Logout</button>
    </div>
</div>
